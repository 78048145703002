<template>
  <div class="form-signin">
    <img class="mb-4" src="../../assets/logo.png" alt="">
    <h1 class="h3 mb-3 font-weight-normal">Environment details</h1>
    <div v-if="session != null && isSessionExpired">
      <div class="alert alert-danger" role="alert">
        <p class="mb-0"><strong><font-awesome-icon icon="ghost"></font-awesome-icon>&nbsp;Environment killed</strong></p>
      </div>
      <div class="alert alert-info">
        <p>{{ session.created_at }}</p>
        <p>{{ session.guests }}</p>
      </div>
    </div>
    <div v-if="session != null && !isSessionExpired">
      <p>
        <a v-if="ownerLoginLink" :href="ownerLoginLink" target="_blank">
          <span class="badge badge-pill badge-danger"><font-awesome-icon icon="user-shield"></font-awesome-icon></span>
          &nbsp;Administrator login&nbsp;<font-awesome-icon icon="external-link-alt"></font-awesome-icon>
        </a>
        <span v-else>
          Generating login <font-awesome-icon icon="spinner" spin></font-awesome-icon>
        </span>
      </p>
      <hr />
      <p v-for="guest in guestLoginLinks" :key="guest.email" class="mb-3">
        <a :href="guest.sso_url" target="_blank">
          <span class="badge badge-pill badge-info"><font-awesome-icon icon="user-tag"></font-awesome-icon></span>
          &nbsp;{{ guest.email }} login&nbsp;<font-awesome-icon icon="external-link-alt"></font-awesome-icon>
        </a>
      </p>
      <hr v-if="guestLoginLinks.length !== 0" />
      <h5>Share</h5>
      <div class="alert alert-warning">
        <p class="mb-0"><strong><font-awesome-icon icon="exclamation-triangle"></font-awesome-icon></strong>&nbsp;Cloud9 shell will share owner credentials with guests</p>
      </div>
      <div class="input-group mb-3">
        <input ref="inputGuestEmail" type="text" class="form-control" placeholder="Email" :disabled="processing">
        <div class="input-group-append">
          <button class="btn btn-primary" type="button" :disabled="processing" v-on:click="shareEnvironment()">
            <font-awesome-icon v-if="!processing" icon="arrow-right"></font-awesome-icon>
            <font-awesome-icon v-if="processing" icon="spinner" spin></font-awesome-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="alert">
      <router-link to="/internal/sessions" class="btn btn-outline-secondary"><font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;Back</router-link>
    </div>
    <p class="mt-5 mb-3 text-muted">&copy; Devoteam Revolve 2021</p>
  </div>
</template>

<script>

import CloudEndpointService from '@/services/cloud-endpoint'

const cloudEndpoint = new CloudEndpointService()

export default {
  name: 'InternalHome',
  data () {
    return {
      session: null,
      processing: false,
      ownerLoginLink: null,
      guestLoginLinks: []
    }
  },
  mounted: function () {
    const that = this
    cloudEndpoint.getSession(this.$route.params.sessionId).then(response => {
      that.session = response
      if (!that.isSessionExpired) {
        cloudEndpoint.getOwnerLoginUrl(this.$route.params.sessionId).then(response => {
          that.ownerLoginLink = response.sso_url
        }).catch(err => { console.error(err) })
      }
    }).catch(err => { console.error(err) })
  },
  computed: {
    isSessionExpired: function () {
      if (!this.session) {
        return true
      }

      const nowMidnight = new Date();
      nowMidnight.setHours(0,0,0,0);
      let nowMidnightString = nowMidnight.toISOString()
      // Trick because Javascript does not implements properly ISO8601 specification
      // http://www.ecma-international.org/ecma-262/5.1/#sec-15.9.1.15
      if (!nowMidnightString.endsWith('Z')) {
        nowMidnightString += 'Z'
      }

      return nowMidnightString > this.session.created_at
    }
  },
  methods: {
    shareEnvironment: function () {
      const guestEmail = this.$refs.inputGuestEmail.value
      this.$refs.inputGuestEmail.value = ''
      this.processing = true

      const that = this
      cloudEndpoint.shareEnvironment(this.session.id, guestEmail).then(response => {
        this.processing = false
        that.guestLoginLinks.push(response)
      }).catch(err => {
        this.processing = false
        console.error(err)
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
