<template>
  <div class="form-signin">
    <img class="mb-4" src="../../assets/logo.png" alt="">
    <h1 class="h3 mb-3 font-weight-normal">My environments</h1>
    <table v-if="sessions" class="table table-stripped">
      <tr>
        <th></th>
        <th>Created at</th>
        <th>Guests</th>
      </tr>
      <tr v-for="session in sessions" :key="session.id">
        <td>
          <router-link :to="'/internal/session/' + session.id">
            <span v-if="isExpired(session)" class="badge badge-pill badge-secondary"><font-awesome-icon icon="ghost"></font-awesome-icon>&nbsp;Killed</span>
            <span v-if="!isExpired(session)" class="badge badge-pill badge-danger"><font-awesome-icon icon="paw"></font-awesome-icon>&nbsp;Live</span>
          </router-link>
        </td>
        <td>
            {{session.created_at}}
        </td>
        <td>
            {{session.guests}}
        </td>
      </tr>
    </table>
    <div v-if="!sessions" class="alert alert-light">
      <font-awesome-icon icon="spinner" spin></font-awesome-icon>&nbsp;Loading...
    </div>
    <div class="alert">
      <router-link to="/internal/home" class="btn btn-outline-secondary"><font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;Back</router-link>
    </div>
    <p class="mt-5 mb-3 text-muted">&copy; Devoteam Revolve 2021</p>
  </div>
</template>

<script>

import CloudEndpointService from '@/services/cloud-endpoint'

const cloudEndpoint = new CloudEndpointService()

export default {
  name: 'InternalSessionList',
  data () {
    return {
      sessions: null
    }
  },
  mounted: function () {
    const that = this
    cloudEndpoint.getUserSessions().then(response => {
        that.sessions = response.sort((a, b) => {
          if (a > b.created_at) {
            return -1
          } else if (a.created_at < b.created_at) {
            return 1
          } else {
            return 0
          }
        })
        // Display only last 5 sessions
        that.sessions = that.sessions.slice(0, 5)
    })
  },
  methods: {
    isExpired: function (session) {
      const nowMidnight = new Date();
      nowMidnight.setHours(0,0,0,0);
      let nowMidnightString = nowMidnight.toISOString()
      // Trick because Javascript does not implements properly ISO8601 specification
      // http://www.ecma-international.org/ecma-262/5.1/#sec-15.9.1.15
      if (!nowMidnightString.endsWith('Z')) {
        nowMidnightString += 'Z'
      }

      return nowMidnightString > session.created_at
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.form-signin {
  max-width: 800px !important;
}

</style>
