import { API } from 'aws-amplify'

/**
 * Utils service calling the api (useful if we need to add custom headers)
 */
class ApiService {
  constructor (apiName) {
    this.apiName = apiName
  }

  async apiGet (path, queryParameters) {
    const params = {
      headers: {},
      queryStringParameters: queryParameters
    }
    return API.get(this.apiName, path, params)
  }

  async apiPut (path, bodyParameters) {
    const params = {
      headers: {},
      body: bodyParameters
    }
    return API.put(this.apiName, path, params)
  }

  async apiPost (path, bodyParameters) {
    const params = {
      headers: {},
      body: bodyParameters
    }
    return API.post(this.apiName, path, params)
  }

  async apiDelete (path) {
    const params = {
      headers: {}
    }
    return API.del(this.apiName, path, params)
  }
}

/**
 * Cloud endpoint: contains "functional" methods to call in components.
 * Useful for local testing: can be mocked.
 */
const backendApi = new ApiService('BackendApi')

export default class CloudEndpointService {

  async getUserSessions () {
    return backendApi.apiGet('/sessions')
  }

  async getSession (sessionId) {
    return backendApi.apiGet('/sessions/' + sessionId)
  }

  async createSession () {
    return backendApi.apiPost('/sessions/create')
  }

  async getOwnerLoginUrl (sessionId) {
    return backendApi.apiGet('/sessions/' + sessionId + '/owner-url')
  }

  async shareEnvironment (sessionId, guestEmail) {
    return backendApi.apiPost('/sessions/' + sessionId + '/share', {
      'email': guestEmail
    })
  }
}
