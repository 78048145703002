<template>
  <router-view />
</template>

<script>
export default {
  name: 'Internal'
}
</script>

<style>
</style>
