import Vue from 'vue'
import Router from 'vue-router'

// Root
import Home from '@/Home'
import Auth from '@/Auth'
import Internal from '@/Internal'

import InternalHome from '@/components/internal/InternalHome'
import InternalSessionList from '@/components/internal/InternalSessionList'
import InternalSessionId from '@/components/internal/InternalSessionId'

import UserStore from '@/store/user'

import { Hub } from 'aws-amplify'

const listener = async data => {
  switch (data.payload.event) {
    case 'signIn':
      await getUser()
      router.push({ path: '/internal/home' }, () => { })
      break
    case 'signIn_failure':
      await getUser()
      router.push({ path: '/' }, () => { })
      break
    case 'signOut':
      await getUser()
      router.push({ path: '/' }, () => { })
      break
    // Below, the unused codes
    // case 'signUp':
    // case 'tokenRefresh':
    // case 'tokenRefresh_failure':
    // case 'configured':
  }
}
Hub.listen('auth', listener)

// Define getUser function
async function getUser () {
  return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((data) => {
    if (data) {
      // When current authenticated user found, store it in local storage
      const userEmail = data.signInUserSession.idToken.payload.email
      UserStore.commit('setUserEmail', userEmail)
      // Return user
      return data
    } else {
      // By default, remove elements from local storage
      UserStore.commit('setUserEmail', null)
      return null
    }
  }).catch(() => {
    // When current authenticated user not found, drop local storage
    UserStore.commit('setUserEmail', null)
    return null
  })
}

Vue.use(Router)

const router = new Router({
  routes: [{
    path: '/',
    component: Home
  }, {
    path: 'auth',
    component: Auth,
  }, {
    path: '/internal',
    component: Internal,
    meta: { requiresAuth: true },
    children: [{
      path: 'home',
      component: InternalHome
    }, {
      path: 'session/:sessionId',
      component: InternalSessionId
    }, {
      path: 'sessions',
      component: InternalSessionList
    }]
  }]
})

// Firewall: if user is not authenticated, and page requires auth, redirect to authentication view
router.beforeResolve(async (to, from, next) => {
  // Authentication redirection
  const user = await getUser()
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!user) {
      return next({
        path: '/main/auth'
      })
    }
  }
  return next()
})

export default router
