<template>
  <div class="form-signin">
    <img class="mb-4" src="../../assets/logo.png" alt="">
    <h1 v-if="!creating" class="h3 mb-3 font-weight-normal">One-click Cloud9 environments</h1>
    <h1 v-if="creating" class="h5 mb-3 font-weight-normal">Creating...</h1>
    <div v-if="creating" class="loading-container">
      <div class="loading-anim">
        <div class="loading-border out"></div>
        <div class="loading-border in"></div>
        <div class="loading-border mid"></div>
      </div>
    </div>
    <button v-if="!creating && (!session || !session.cloud9_id)" v-on:click="createEnvironment()" class="btn btn-lg btn-danger btn-block" type="submit">Generate !</button>
    <div class="alert alert-danger" v-if="failed && errorMessage">{{errorMessage}}</div>
    <div class="btn btn-lg btn-outline-danger" v-if="creating && message">{{message}}</div>
    <div class="alert">
      <router-link to="/internal/sessions" class="btn btn-sm btn-link">Current Environments</router-link>
    </div>
    <p class="mt-5 mb-3 text-muted">&copy; Devoteam Revolve 2021</p>
  </div>
</template>

<script>

import SessionStore from '@/store/session'
import CloudEndpointService from '@/services/cloud-endpoint'

const cloudEndpoint = new CloudEndpointService()

export default {
  name: 'InternalHome',
  data () {
    return {
      creating: false,
      failed: false,
      polling: null,
      session: null,
      message: null,
      errorMessage: null
    }
  },
  watch: {
    session: function (current) {
      if (current && current.cloud9_id) {
        this.creating = false
        clearInterval(this.polling)
        this.$router.push('/internal/session/' + current.id)
      }
    }
  },
  methods: {
    createEnvironment: function () {
      this.creating = true
      this.failed = false
      this.errorMessage = null
      this.message = this.generateWaitingMessage()

      const that = this
      cloudEndpoint.createSession().then(createdSession => {
        SessionStore.commit('setSessionItem', createdSession)
        that.polling = setInterval(function () {
          that.message = that.generateWaitingMessage()
          cloudEndpoint.getSession(SessionStore.state.sessionItem.id).then(retrievedSession => {
            that.session = retrievedSession
            SessionStore.commit('setSessionItem', retrievedSession)
          })
        }, 3 * 1000)
      }).catch(err => {
          that.creating = false
          that.failed = true
          that.errorMessage = err.response.data.message
          console.error(err)
      })
    },
    generateWaitingMessage: function () {
      const firstPart = [
        "Inserting",
        "Framing",
        "Iterating upon",
        "Generating",
        "Crafting",
        "Randomizing",
        "Summoning",
        "Compiling",
        "Building",
        "Spawning",
        "Rendering",
        "Solving",
        "Flipping",
        "Storing"
      ]

      const secondPart = [
        "bits",
        "buckets",
        "gravity",
        "AWS bills",
        "knowledge",
        "cats",
        "clouds",
        "dragons",
        "a touch of magic",
        "devops stuff",
        "encryption keys",
        "a comforting togetherness",
        "souls",
        "rockets",
        "chaos monkeys",
        "planets",
        "kernel",
        "pipelines",
        "artifacts",
        "branches",
        "containers",
        "IaaS",
        "FaaS"
      ]

      const randomFirst = firstPart[Math.floor(Math.random() * firstPart.length)];
      const randomSecond = secondPart[Math.floor(Math.random() * secondPart.length)];
      return randomFirst + " " + randomSecond
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.btn-outline-danger, .btn-outline-danger:hover {
  cursor: default !important;
}

/* Partial adaptation of https://codepen.io/Vestride/pen/izIoh */

.loading-container {
  margin: 20px;
  width: calc(100% - 40px);
  height: auto;
}
.loading-anim {
  position: relative;
  width: 200px;
  height: 200px;
  margin: auto;
  perspective: 800px;
  transform-style: preserve-3d;
  transform: translateZ(0) rotateY(0deg) rotateX(0deg) rotateZ(0deg) scale(1);
  transition: all .2s ease-out;
}
.loading-anim .circle {
  width: 100%;
  height: 100%;
  animation: spin 5s linear infinite;
}
.loading-anim .loading-border {
  position: absolute;
  border-radius: 50%;
  border: 5px solid #e20613;
}
.loading-anim .out {
  top: 15%;
  left: 15%;
  width: 70%;
  height: 70%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: spin 2s linear reverse infinite;
}
.loading-anim .in {
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation: spin 2s linear infinite;
}
.loading-anim .mid {
  top: 40%;
  left: 40%;
  width: 20%;
  height: 20%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
