<template>
  <div class="form-signin">
    <img class="mb-4" src="./assets/logo.png" alt="">
    <h1 class="h3 mb-3 font-weight-normal">Authentication required</h1>
    <button v-on:click="signIn()" class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
    <p class="mt-5 mb-3 text-muted">&copy; Devoteam Revolve 2021</p>
  </div>
</template>

<script>

import { Auth } from 'aws-amplify'

export default {
  name: 'Auth',
  methods: {
    signIn: function () {
      Auth.federatedSignIn().catch(err => console.log(err))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
</style>
