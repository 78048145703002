import Vue from 'vue'
import App from './App.vue'
import router from './router'

// AWS Amplify imports
import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSpinner, faCheck, faTimes, faExternalLinkAlt,
  faArrowAltCircleLeft, faGhost, faSyncAlt, faArrowRight,
  faList, faPaw, faExclamationTriangle, faUserShield, faUserTag
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'


// Bootstrap include
require('../node_modules/bootstrap/scss/bootstrap.scss')
require('./assets/global.css')

Vue.config.productionTip = false

// Init AWS Amplify
Amplify.configure({
  Auth: {
    // Cognito user config
    identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.VUE_APP_COGNITO_REGION,
    identityPoolRegion: process.env.VUE_APP_AWS_COGNITO_IDENTITY_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID,

    // Website config
    mandatorySignIn: true,

    // Hosted UI config
    oauth: {
      domain: process.env.VUE_APP_COGNITO_DOMAIN,
      scope: process.env.VUE_APP_COGNITO_SCOPES.split(","),
      redirectSignIn: process.env.VUE_APP_WEBSITE_URL,
      redirectSignOut: process.env.VUE_APP_WEBSITE_URL,
      responseType: 'code'
    }
  },
  API: {
    endpoints: [
      {
        // API config
        name: 'BackendApi',
        endpoint: process.env.VUE_APP_API_ENDPOINT,
        region: process.env.VUE_APP_API_REGION
      }
    ]
  }
})

Vue.use(AmplifyPlugin, AmplifyModules)

// Init FontAwesome icons
library.add(
  faSpinner, faCheck, faTimes, faExternalLinkAlt,
  faArrowAltCircleLeft, faGhost, faSyncAlt, faArrowRight,
  faList, faPaw, faExclamationTriangle, faUserShield, faUserTag
)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
